import React from "react";
import StructuredTextRenderer from "../../StructuredTextRenderer/StructuredTextRenderer";
import { Section } from "@soundtrackyourbrand/ui/src/Section";
import PropTypes from "prop-types";
import Personalization from "../../Personalization/Personalization";
import {graphql} from "gatsby";

const TitleText = ({ block }) => {
  const overline = block.overline;
  const title = block.title;
  const structuredText = block.text;
  const visibleFor = block.visibleFor

  return (
    <Personalization visibleFor={visibleFor}>
      <Section row="r-top">
        <div className="col sm-6">
          {overline && <p className="text4 subtle mb-0">{overline}</p>}
          <p className="text7">{title}</p>
        </div>
        <div className="col sm-6">
          <StructuredTextRenderer
            data={structuredText}
            isParagraphClassName="mb-7 subtle"
            isHeadingClassName="mb-5"
          />
        </div>
      </Section>
    </Personalization>
  );
};

export default TitleText;

TitleText.propTypes = {
  block: PropTypes.shape({
    overline: PropTypes.string,
    title: PropTypes.string.isRequired,
    text: PropTypes.object.isRequired,
    visibleFor: PropTypes.string.isRequired,
  }),
};

export const query = graphql`
  fragment TitleText on DatoCmsTitletext {
    model {
      apiKey
    }
    id: originalId
    overline
    title
    visibleFor
    text {
      value
      blocks {
        __typename
        ...Link
      }
    }
  }
`